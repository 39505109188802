import React from "react"
import { Layout } from "@components/layout"
import { wrapper } from "./styles/rodo.module.scss"

const Rodo = ({ pageContext }) => {
  const { page } = pageContext

  return (
    <Layout {...page}>
      <div className={wrapper}>
        <h1>Rozporządzenie o ochronie danych osobowych</h1>
        <iframe src="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/Obowi%C4%85zek-informacyjny-RODO-Fundacja-Beneficjenci.pdf"></iframe>
      </div>
    </Layout>
  )
}

export default Rodo
